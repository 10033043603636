<template>
  <div class="Brokerage">
    <div class="top-title">
      <div class="top-text">佣金设置</div>
      
    </div>

<div class="Brokerage-box">
      <el-form ref="form" label-width="100px">
        <div class="Brokerage-li">
         <el-form-item label="超级会员:" >
                  <el-input type="number" v-model="list.super.rate"></el-input>
          </el-form-item>
          <span>%</span>
        </div>
        <div class="Brokerage-li">
         <el-form-item label="超级会员外挂:" >
                  <el-input type="number" v-model="list.crosser.rate"></el-input>
          </el-form-item>
          <span>%</span>
        </div>
        <div class="Brokerage-li">
         <el-form-item label="经理:" >
                  <el-input type="number" v-model="list.manager.rate"></el-input>
          </el-form-item>
          <span>%</span>
        </div>
        <div class="Brokerage-li">
              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="tijiao()"
                >提交</el-button>
        </div>
        </el-form>
</div>

  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 下拉框
      seeks: {
        
      },
      list:{
            super:{
              rate:""
            },
            crosser:{
              rate:""
            },
            manager:{
               rate:""
            },
      },
    };
  },
  created() {
      this.getSong()
  },
  mounted() {},

  methods: {
    // 查询接口
      async getSong(){
          let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'CommissionRuleEntity',
        handleMode:'fetchall',
            })
            if(this.$common.verifyAjaxResult(res,false)==false) return;
            this.list = res.Result
      },
              //弹窗
      tijiao(){
          this.$confirm('是否确认修改', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.tijiaos()
          }).catch(action => {
            console.log("取消");
          })
      },
     // 提交接口
    async tijiaos(){
      		let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'CommissionRuleEntity',
        handleMode:'updateRuleRates',
            cfgData:{
                super:{ rate:this.list.super.rate, },
                crosser:{ rate:this.list.crosser.rate, },
                manager:{ rate:this.list.manager.rate, },
                } 
            });
         if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('更改成功', '消息提示', {confirmButtonText: '确定'});
          
    },

  },
  computed: {},
};
</script>


<style  scoped>
/* 搜索栏样式 */
.top-title{
    width: 94%;
    margin: 0 auto;
}
.top-text{
    height: 30px;
    font-size: 18px;
}
.Brokerage-box{
  width: 200px;
  margin: 100px auto;
}
.Brokerage-li{
   width: 100%;
  display: flex;
  justify-content: left;
  height: 70px;
  
}
.Brokerage-li span{
  font-size: 20px;
  line-height: 40px;
  height: 100%;
}
/* 搜索按钮 */
.biy-icon {
    width: 100px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    padding: 0;
    margin-left: 80px;
    background-color: #01847F;
  }
</style>